import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import ReactMarkdown from "react-markdown";

const BlogPost = ({ data }) => {
  return (
    <Link
      className={`
      flex flex-col rounded-lg shadow-lg overflow-hidden 
      transform hover:-translate-y-1 hover:scale-105 hover:shadow-xl transition ease-in-out duration-300`}
      to={data.slug}
    >
      <div className="flex-shrink-0">
        <Img
          className="h-48 w-full object-cover"
          fluid={data.heroImage.fluid}
        />
      </div>
      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          <p className="text-sm leading-5 font-medium text-indigo-600">
            {data.publication || <>&nbsp;</>}
          </p>
          <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
            {data.title}
          </h3>
          <ReactMarkdown className="mt-3 text-base leading-6 text-gray-500">
            {data.description.childMarkdownRemark.rawMarkdownBody}
          </ReactMarkdown>
        </div>
      </div>
    </Link>
  );
};

export default BlogPost;
